import React from 'react';
import { useLocation } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import { useAuth } from '../context/AuthContext';

const HeaderWrapper: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const iconColor = isHomePage ? 'black' : 'white';
  const filledbg = !isHomePage;

  return <MobileHeader isLoggedIn={isLoggedIn} iconColor={iconColor} filledbg={filledbg} />;
};

export default HeaderWrapper; 