import React from 'react';
import '../styles/About.css';

const TermsOfService: React.FC = () => {
    return (
        <div className="about-container">
            <div className="about-header">
                <h2 className="title">Terms of Service</h2>
            </div>
            <p className="description">
                These Terms of Service govern your use of our website and services. By accessing or using our site, you agree to be bound by these terms.
            </p>

            <div className="about-tomatoes">
                <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
            </div>

            <div className="cards-section">
                {/* Card 0 */}
                <p className="card-title">Use of Our Service</p>
                <p className="expanded-content">
                    You agree to use our service only for lawful purposes and in accordance with these Terms. You must not use our site in any way that breaches any applicable local, national, or international law or regulation.
                </p>

                {/* Card 1 */}
                <p className="card-title">Account Responsibilities</p>
                <p className="expanded-content">
                    You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.
                </p>

                {/* Card 2 */}
                <p className="card-title">Intellectual Property</p>
                <p className="expanded-content">
                    All content included on this site, such as text, graphics, logos, images, and software, is the property of our company or its content suppliers and protected by international copyright laws.
                </p>

                {/* Card 3 */}
                <p className="card-title">Termination</p>
                <p className="expanded-content">
                    We may terminate or suspend your access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                </p>

                {/* Card 4 */}
                <p className="card-title">Changes to Terms</p>
                <p className="expanded-content">
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.
                </p>
            </div>

            <p className="disclaimer">
                If you have any questions about these Terms of Service, please contact us at hello@ratatouille.nyc.
            </p>
        </div>
    );
};

export default TermsOfService; 