import React from 'react';
import '../styles/About.css';

const Contact: React.FC = () => {
    return (
        <div className="about-container">
            <div className="about-header">
                <h2  className="title">Contact</h2>
            </div>

            <p className="description">
                We are a small team of engineers surviving on sourdough bread, internet chef memes and leftover meals.
            </p>

            <div className="contact-meme-image">
                <img src="/shawarma.png" alt="Chef Meme" className="logo" />
            </div>

            <p className="contact-info">
                You can reach us by email at <a className="highlight-email" href="mailto:hello@ratatouille.nyc">hello@ratatouille.nyc</a>
            </p>

            <div className="about-tomatoes-bottom">
                <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
            </div>

        </div>
    );
};

export default Contact;