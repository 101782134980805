import React from 'react';
import '../styles/About.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="about-container">
            <div className="about-header">
                <h2 className="title">Privacy Policy</h2>
            </div>
            <p className="description">
                This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from our site.
            </p>

            <div className="about-tomatoes">
                <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
            </div>

            <div className="cards-section">
                {/* Card 0 */}
                <p className="card-title">Information We Collect</p>
                <p className="expanded-content">
                    We collect information from you when you register on our site, place an order, subscribe to our newsletter, or fill out a form. The types of information we may collect include your name, email address, mailing address, phone number, and payment information.
                </p>

                {/* Card 1 */}
                <p className="card-title">How We Use Your Information</p>
                <p className="expanded-content">
                    We use the information we collect to process transactions, send periodic emails, and improve our website. We may also use your information to respond to inquiries and support requests.
                </p>

                {/* Card 2 */}
                <p className="card-title">Sharing Your Information</p>
                <p className="expanded-content">
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
                </p>

                {/* Card 3 */}
                <p className="card-title">Your Consent</p>
                <p className="expanded-content">
                    By using our site, you consent to our website privacy policy.
                </p>

                {/* Card 4 */}
                <p className="card-title">Changes to Our Privacy Policy</p>
                <p className="expanded-content">
                    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                </p>
            </div>

            <p className="disclaimer">
                If you have any questions about this Privacy Policy, please contact us at hello@ratatouille.nyc.
            </p>
        </div>
    );
};

export default PrivacyPolicy; 