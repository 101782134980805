import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { signupUser } from '../services/api';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { login, logout, user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Apple Sign-In
    window.AppleID.auth.init({
      clientId: 'YOUR_CLIENT_ID',
      scope: 'name email',
      redirectURI: 'YOUR_REDIRECT_URI',
      state: 'state',
      usePopup: true,
    });
  }, []);

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    console.log('Google login success:', credentialResponse);
    try {
      login({ user_name: 'GoogleUser' });
      setSuccessMessage('You have successfully logged in with Google');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Error processing Google login:', error);
      setError('Failed to process Google login. Please try again.');
    }
  };

  const handleAppleLogin = () => {
    window.AppleID.auth.signIn();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      if (isLogin) {
        const loginSuccess = login({ user_name: email });
        if (loginSuccess) {
          setSuccessMessage('You have successfully logged in');
          setTimeout(() => {
            navigate('/recipes');
          }, 2500);
        } else {
          setError('Login failed. Please check your credentials.');
        }
      } else {
        const response = await signupUser(email, password, userName, 'en');
        if (response.authentication_status) {
          setIsLogin(true);
          setError('Signup successful! Please log in.');
        } else {
          setError('Signup failed. Please try again.');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message || 'Authentication failed. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Authentication error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h2 className="title">Account</h2>
      </div>
      <p className="description">Welcome chef, we are excited to join you in the kitchen</p>

      <div className="password-protection-box">
        {isLoggedIn ? (
          <div className="login-title-container">
            <p>Welcome, {user?.user_name}</p>
            <button className="reset-password-button">Reset Password</button>
            <button onClick={logout} className="sign-out-button">
              Sign Out
            </button>
          </div>
        ) : (
          <>
            <div className="login-title-container"> 
              <p className="login-title">{isLogin ? 'Login' : 'Sign Up'}</p>
              <hr className="title-divider" />
            </div>
            {error && (
              <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
                {error}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              {!isLogin && (
                <input
                  type="text"
                  className="form-input"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Chef Name"
                  required
                />
              )}
              <input
                type="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <div className="google-login-wrapper">
                <GoogleOAuthProvider clientId="chef-430714">
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      console.error('Google Login Failed');
                      setError('Google login failed. Please try again.');
                    }}
                    type="standard"
                    theme="outline"
                    size="large"
                    text="signin_with"
                    shape="rectangular"
                    width="100%"
                    locale="en"
                    useOneTap={false}
                  />
                </GoogleOAuthProvider>
              </div>

              <button 
                onClick={handleAppleLogin} 
                className="social-login-button apple-login-button"
                type="button"
              >
                <svg
                  className="apple-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                >
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                </svg>
                Sign in with Apple
              </button>
              <button type="submit" className="submit-button">
                {isLogin ? 'Login' : 'Sign Up'}
              </button>
            </form>
            <div className="login-switch-text-container">
              <p className="login-switch-text">
                {isLogin ? "Don't have an account? " : "Already have an account? "}
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  setIsLogin(!isLogin);
                  setError('');
                }}>
                  {isLogin ? 'Sign Up' : 'Login'}
                </a>
              </p>
            </div>
            {successMessage && (
            <div className="success-message" style={{ color: 'green', marginBottom: '10px' }}>
              {successMessage}
            </div>
            )}
          </>
        )}
      </div>
      <div className={`about-tomatoes-bottom ${isLoggedIn ? 'signed-in' : isLogin ? 'signed-out-login' : 'signed-out-signup'}`}>
        <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
      </div>
    </div>
  );
};

export default Login;