import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HeaderWrapper from './components/HeaderWrapper';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import RecipeSuggestions from './pages/RecipeSuggestions';
import PasswordProtection from './components/PasswordProtection';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import KitchenTips from './pages/KitchenTips';
import RecipesHistory from './pages/RecipesHistory';
import { AuthProvider } from './context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import './styles/App.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function getSessionUUID() {
    let sessionUUID = sessionStorage.getItem('session_uuid');
    if (!sessionUUID) {
      sessionUUID = uuidv4();
      sessionStorage.setItem('session_uuid', sessionUUID);
      console.log("Session UUID:", sessionUUID);
    }
    return sessionUUID;
  }
  
  const sessionUUID = getSessionUUID();

  const handleAuthentication = (password: string): boolean => {
    const correctPassword = 'yeschef';
    const sanitizedPassword = password
      .trim()
      .toLowerCase()
      .replace(/[^\w]/g, '');

    const authenticated = sanitizedPassword === correctPassword;
    setIsAuthenticated(authenticated);
    return authenticated;
  };

  if (!isAuthenticated) {
    return <PasswordProtection onAuthenticate={handleAuthentication} />;
  }

  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <div className="App">
            <HeaderWrapper />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/recipes" element={<RecipeSuggestions />} />
              <Route path="/about" element={<About />} />
              <Route path="/your_recipes" element={<RecipesHistory sessionUUID={sessionUUID} />} />
              <Route path="/kitchen_tips" element={<KitchenTips />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Login />} />
              <Route path="/account" element={<Login />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;