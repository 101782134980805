import React, { useState, useEffect } from 'react';
import IngredientInput from '../components/IngredientInput';
import RecipeList from '../components/RecipeList';
import { getRecipeSuggestions, Recipe } from '../services/api';
import "../styles/buttons.css";

const RecipeSuggestions: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const [customizeVisible, setCustomizeVisible] = useState<boolean>(false);

  const handleIngredientSubmit = async (ingredients: string[], cuisine: string, dietaryRestrictions: string[]) => {
    console.log("Submitting ingredients:", ingredients);
    setLoading(true);
    setError(null);
    setApiCalled(true);
  
    const isVegan = dietaryRestrictions.includes('Vegan');
    const isVegetarian = dietaryRestrictions.includes('Vegetarian');
    const isGlutenFree = dietaryRestrictions.includes('Gluten-Free');
    const isDairyFree = dietaryRestrictions.includes('Dairy-Free');
  
    try {
      console.log("Calling getRecipeSuggestions");
      const fetchedRecipes = await getRecipeSuggestions({
        ingredients,
        cuisine,
        is_vegan: isVegan,
        is_vegetarian: isVegetarian,
        is_gluten_free: isGlutenFree,
        is_dairy_free: isDairyFree,
      });
      console.log("Received recipes:", fetchedRecipes);
      setRecipes(fetchedRecipes);
      setApiSuccess(true);
    } catch (err) {
      console.error('Error getting recipe suggestions:', err);
      setError('Error fetching recipes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    setRecipes(null);
    setApiSuccess(false);
    setApiCalled(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        // First fade out current text
        const spans = document.querySelectorAll('.loading-text span');
        spans[currentTextIndex].classList.remove('fade-in');
        spans[currentTextIndex].classList.add('fade-out');
  
        // Wait for fade out, then fade in next text
        setTimeout(() => {
          setCurrentTextIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % 3;
            // Remove fade-out and add fade-in to next text
            spans[prevIndex].classList.remove('fade-out');
            spans[nextIndex].classList.add('fade-in');
            return nextIndex;
          });
        }, 500); // Half a second for fade out
  
      }, 3000); // Total cycle time
  
      return () => clearInterval(interval);
    }
  }, [loading, currentTextIndex]);

  useEffect(() => {
    if (recipes) {
      setButtonsVisible(true);
    } else {
      setButtonsVisible(false);
    }
  }, [recipes]);

  const toggleCustomizeContainer = () => {
    setCustomizeVisible(!customizeVisible);
  };

  return (
    <div className="recipe-suggestions">
      {!apiSuccess && (
        <div className="input-section">
          <h1 className="find-recipes">Find Recipes</h1>
          <p className="enter-ingredients">Enter at least three ingredients below to get recipes with ingredients you already have on hand. </p>
          <div className="divider"></div>
          <IngredientInput onSubmit={handleIngredientSubmit} />
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <button className="close-button" onClick={handleCloseLoading}>×</button>
            <p>Loading recipes...</p>
            <video autoPlay loop muted playsInline style={{ maxWidth: '300px', width: '300px' }}>
              <source src="/rat_stir_looped.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="loading-text">
              {['Coming up with recipe ideas', "Translating your grandmother's cookbook",'Consulting with top chefs'].map((text, index) => (
                <span key={index} className={index === currentTextIndex ? 'fade-in' : ''}>
                  {text}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      {error && <div className="error">{error}</div>}
      {recipes && (
        <div>
          <RecipeList recipes={recipes} />
          <div className={`expand-button-container ${buttonsVisible ? 'active' : ''}`}>
            <button className="button back-button" onClick={handleBackClick}>Back</button>
            <button className="button share-recipe-button">Share Recipes</button>
          </div>
          <div className={`customize-recipe-container ${customizeVisible ? 'active' : ''}`}>
            {/* Customize recipe content */}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipeSuggestions;